import React from "react";
import classNames from "classnames";
import { Link } from "components/Link";
import { VARIANT } from "helpers/constants";

const baseStyles = classNames(
  "font-medium antialiased group flex items-center transition duration-500 space-x-3",
  "outline outline-2 outline-transparent focus-visible:outline-blue-500 outline-offset-1",
  "border-2 rounded-3xl sm:rounded-7.5",
);

const baseVariantStyles = {
  [VARIANT.orange]: {
    border: "border-orange-primary hover:border-warm-orange-primary",
    bgColor: "bg-orange-primary hover:bg-warm-orange-primary",
    textStyle: "text-white",
    shadow:
      "shadow-glow-6/30/40 hover:shadow-glow-12/60/60 active:shadow-glow-12/80/80",
  },
  [VARIANT.dark]: {
    border: "border-black-primary hover:border-dark-primary",
    bgColor: "bg-black-primary hover:bg-dark-primary",
    textStyle: "text-white",
    shadow: "shadow-6/30/20 hover:shadow-12/60/60 active:shadow-12/80/100",
  },
  [VARIANT.light]: {
    border: "border-white",
    bgColor: "bg-white",
    textStyle: "text-black-primary",
    shadow: "shadow-6/30/20 hover:shadow-12/60/40 active:shadow-12/80/60",
  },
  [VARIANT.darkTransparent]: {
    border: "border-transparent rounded-none",
    bgColor: "bg-transparent",
    textStyle: "text-black-primary",
    shadow: "shadow-none",
  },
  [VARIANT.lightTransparent]: {
    border: "border-transparent rounded-none",
    bgColor: "bg-transparent",
    textStyle: "text-white",
    shadow: "shadow-none",
  },
  [VARIANT.orangeTransparent]: {
    border: "border-transparent rounded-none",
    bgColor: "bg-transparent",
    textStyle: "text-orange-primary",
    shadow: "shadow-none",
  },
  [VARIANT.blackOutlined]: {
    border: "border-2 border-black-700",
    bgColor: "bg-transparent hover:bg-black-700",
    textStyle: "text-black-primary hover:text-white",
    shadow: "shadow-none",
  },
};

const outlineVariantStyles = {
  [VARIANT.orange]: {
    bgColor: "bg-transparent hover:bg-warm-orange-primary",
    textStyle: "text-orange-primary hover:text-white",
    shadow: "hover:shadow-glow-12/60/60 active:shadow-glow-12/80/80",
  },
  [VARIANT.dark]: {
    bgColor: "bg-transparent hover:bg-dark-primary",
    textStyle: "text-black-primary hover:text-white",
    shadow: "hover:shadow-12/60/60 active:shadow-12/80/100",
  },
  [VARIANT.light]: {
    bgColor: "bg-transparent hover:bg-white",
    textStyle: "text-white hover:text-black-primary",
    shadow: "hover:shadow-12/60/40 active:shadow-12/80/60",
  },
};

const sizesSm = {
  100: "px-5 py-2 text-14/20",
  200: "px-5 py-2.5 text-15/24",
  300: "px-6 py-2 text-18/28",
  400: "px-7 py-4 text-18/28",
};

const sizesMd = {
  100: "sm:px-5 sm:py-2 sm:text-14/20",
  200: "sm:px-5 sm:py-2.5 sm:text-15/24",
  300: "sm:px-6 sm:py-2 sm:text-18/28",
  400: "sm:px-5.5 sm:py-3.5 sm:text-18/28",
};

const sizesLg = {
  100: "md:px-5 md:py-2 md:text-14/20",
  200: "md:px-5 md:py-2.5 md:text-15/24",
  300: "md:px-6 md:py-2.5 md:text-18/28",
  400: "md:px-7 md:py-4 md:text-18/28",
};

const sizesXl = {
  100: "lg:px-5 lg:py-2 lg:text-14/20",
  200: "lg:px-5 lg:py-2.5 lg:text-15/24",
  300: "lg:px-6 lg:py-2 lg:text-18/28",
  400: "lg:px-7 lg:py-4 lg:text-18/28",
};

const sizes = {
  small: classNames(sizesSm[100]),
  large: classNames(sizesSm[200], sizesMd[400]),
  default: classNames(sizesSm[200], sizesLg[300]),
  extraLarge: classNames(sizesXl[400]),
  round: "w-10 h-10 p-1.5 md:w-13 md:h-13 p-2",
};

function getStylesByVariant({ variant, outline }) {
  const styles = baseVariantStyles[variant];
  if (!outline) {
    return styles;
  }
  const outlineStyles = outlineVariantStyles[variant];
  const mergedStyles = { ...styles, ...outlineStyles };
  return mergedStyles;
}

const iconStyle = classNames(
  "transition-transform",
  "group-hover:first:-translate-x-1 group-focus:first:-translate-x-1",
  "group-hover:last:translate-x-1 group-focus:last:translate-x-1",
  "group-active:first:translate-x-0 group-active:last:translate-x-0",
);

function Button({
  href,
  prefetch,
  variant = VARIANT.orange,
  size = "default",
  shadowless = false,
  outline = false,
  children: childrenProp,
  className,
  disableHoverIconStyle = false,
  ...props
}) {
  const { bgColor, textStyle, shadow, border } = getStylesByVariant({
    variant,
    outline,
  });

  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement(child) || typeof child.type === "string") {
      return child;
    }
    return React.cloneElement(child, {
      className: classNames(
        "w-5 h-5 fill-current",
        { [iconStyle]: !disableHoverIconStyle },
        child.props.className,
      ),
    });
  });

  const sizeClassNames = sizes[size];

  const mergedClassNames = classNames(
    baseStyles,
    border,
    bgColor,
    textStyle,
    sizeClassNames,
    { [shadow]: !shadowless },
    className,
  );

  if (href) {
    return (
      <Link href={href} prefetch={prefetch}>
        <a className={mergedClassNames} {...props}>
          {children}
        </a>
      </Link>
    );
  }
  return (
    <button className={mergedClassNames} {...props}>
      {children}
    </button>
  );
}

Button.displayName = "Button";

export { Button };
